// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TextGeneratorIcon(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.62602 8.878C5.02564 7.92801 5.60645 7.06495 6.33602 6.337C7.06398 5.60699 7.92747 5.02612 8.87802 4.627C10.8375 3.81293 13.0378 3.79822 15.008 4.586C15.0278 5.23347 15.2991 5.84776 15.7644 6.29846C16.2296 6.74917 16.8522 7.00082 17.5 7C18.886 7 20 5.886 20 4.5C20 3.114 18.886 2 17.5 2C16.811 2 16.188 2.276 15.737 2.725C13.306 1.752 10.514 1.767 8.10202 2.784C6.91202 3.284 5.84202 4.004 4.92202 4.923C3.9931 5.8522 3.25679 6.95569 2.75536 8.17014C2.25393 9.38459 1.99724 10.6861 2.00002 12H4.00002C4.00002 10.914 4.21102 9.864 4.62602 8.878ZM19.373 15.122C18.972 16.074 18.396 16.93 17.663 17.663C16.93 18.396 16.074 18.972 15.121 19.373C13.1615 20.1871 10.9612 20.2018 8.99102 19.414C8.97176 18.7665 8.70074 18.1521 8.23557 17.7013C7.77041 17.2505 7.14778 16.9989 6.50002 17C5.11402 17 4.00002 18.114 4.00002 19.5C4.00002 20.886 5.11402 22 6.50002 22C7.18902 22 7.81202 21.724 8.26302 21.275C9.45058 21.7544 10.7194 22.0005 12 22C13.9666 22.0038 15.8904 21.4267 17.5302 20.3411C19.1699 19.2555 20.4525 17.7098 21.217 15.898C21.7365 14.6642 22.0028 13.3387 22 12H20C20.0025 13.0722 19.7893 14.1339 19.373 15.122Z"
        fill={props.fill}
      />
      <path
        d="M11.9989 7.46191C9.49694 7.46191 7.46094 9.49791 7.46094 11.9999C7.46094 14.5019 9.49694 16.5379 11.9989 16.5379C14.5009 16.5379 16.5369 14.5019 16.5369 11.9999C16.5369 9.49791 14.5009 7.46191 11.9989 7.46191Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default TextGeneratorIcon;
