import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { createTicket } from "../../app/api/admin/tickets";
import { dropDownData } from "../../app/api/admin/tickets";
import { AxiosError } from "axios";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

type Props = {
  imageUrl: string;
  source: string;
  liveSince: string;
  postId: string;
  closeView: () => void;
};

const ReportExpandView: React.FC<Props> = ({
  imageUrl,
  source,
  liveSince,
  postId,
  closeView,
}) => {
  const [issues, setIssues] = useState<string[]>([]);
  const [selectedIssue, setSelectedIssue] = useState<string>("Issue");
  const [selectedSeverity, setSelectedSeverity] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  const user = useSelector((state: RootState) => state.user.userInfo);

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const issuesData = await dropDownData();
        if (issuesData) {
          setIssues(
            issuesData.map((issueObj: { issue: string }) => issueObj.issue)
          );
        }
      } catch (error) {
        if ((error as AxiosError).response?.status !== 400) {
          console.error("Error fetching issues:", error);
          toast.error("Failed to load issues.", {
            hideProgressBar: true,
          });
        }
      }
    };

    fetchIssues();
  }, []);
  const handleSeverityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSeverity(event.target.value);
  };

  const handleReasonChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 1000) setReason(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!user) {
      toast.error("User details not available. Please login.", {
        hideProgressBar: true,
      });
      return;
    }

    if (selectedIssue === "Issue") {
      toast.error("Please select an issue.", {
        hideProgressBar: true,
      });
      return;
    }
    if (!selectedSeverity) {
      toast.error("Please select severity.", {
        hideProgressBar: true,
      });
      return;
    }

    const ticketData = {
      image: imageUrl,
      source,
      issueType: selectedIssue,
      liveSince,
      createdBy: user.name,
      priority: selectedSeverity,
      email: user.email,
      reason,
      status: "open",
      postId,
      userId: user.userId,
      ticketMarked: "default",
    };
    try {
      await createTicket(ticketData);
      toast.success("Report submitted successfully!", {
        hideProgressBar: true,
      });
    } catch (error) {
      if ((error as AxiosError).response?.status !== 400) {
        toast.error("Post is already reported by you", {
          hideProgressBar: true,
        });
        console.error("Error creating ticket:", error);
      }
    }
    closeView();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => closeView()}
      ></div>

      <div
        className=" relative bg-white rounded-lg shadow-sm z-60 font-poppins p-4"
        style={{ width: "483px", height: "410px", overflow: "hidden" }}
      >
        <div className="flex justify-between">
          <h1 className="text-xl font-bold">Report</h1>
          <button
            onClick={() => closeView()}
            className="text-gray-500 hover:text-gray-700"
          >
            <Close />
          </button>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-start w-[448px] h-[350px] bg-gray-100 font-poppins "
        >
          <div className="w-full mb-4 space-y-2 mt-4">
            <p className="text-sm font-poppins">Issue Type</p>
            <Dropdown
              label="Select an issue"
              options={issues}
              onSelect={setSelectedIssue}
            />
          </div>

          <div className="w-full mb-4 space-y-1">
            <p>Select Issue Severity</p>
            <div className="flex justify-between items-center">
              <div className="flex space-x-2 items-center">
                <input
                  type="radio"
                  id="low"
                  name="severity"
                  value="Low"
                  checked={selectedSeverity === "Low"}
                  onChange={handleSeverityChange}
                  className="h-4 w-4 text-black border-gray-300 focus:ring-black font-poppins accent-black"
                />
                <label htmlFor="low" className="text-sm font-poppins">
                  Low
                </label>
              </div>
              <div className="flex space-x-2  items-center">
                <input
                  type="radio"
                  id="medium"
                  name="severity"
                  value="Medium"
                  checked={selectedSeverity === "Medium"}
                  onChange={handleSeverityChange}
                  className="h-4 w-4 text-black border-gray-300 focus:ring-black accent-black"
                />
                <label htmlFor="medium" className="text-sm font-poppins">
                  Medium
                </label>
              </div>
              <div className="flex space-x-2  items-center">
                <input
                  type="radio"
                  id="high"
                  name="severity"
                  value="High"
                  checked={selectedSeverity === "High"}
                  onChange={handleSeverityChange}
                  className="h-4 w-4 text-black border-gray-300 focus:ring-black accent-black"
                />
                <label htmlFor="high" className="text-sm font-poppins">
                  High
                </label>
              </div>
              <div className="flex space-x-2  items-center">
                <input
                  type="radio"
                  id="severe"
                  name="severity"
                  value="Critical"
                  checked={selectedSeverity === "Critical"}
                  onChange={handleSeverityChange}
                  className="h-4 w-4 text-black border-gray-300 focus:ring-black accent-black"
                />
                <label htmlFor="severe" className="text-sm font-poppins">
                  Critical
                </label>
              </div>
            </div>
          </div>

          <div className="w-full  space-y-2">
            <p className="text-sm font-poppins">Select Reason (optional) </p>
            <textarea
              value={reason}
              onChange={handleReasonChange}
              rows={3}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Explain the reason if needed..."
            />
            <p className="text-sm font-poppins">{reason.length}/1000</p>
          </div>

          <div className="m-auto">
            <input
              type="submit"
              value="Submit Report"
              className="text-sm font-poppins self-end px-4 py-2 bg-black text-white rounded-full  cursor-pointer"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportExpandView;
