import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";

import CrossIcon from "../../../assets/icons/CrossIcon";
import { createSnapshot } from "../../../app/api/trendSnapshot";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUserId } from "../../../app/api/user/user";
import { FeedItem } from "../../../interface/DesignerInterface";
import { TrendInterface } from "../../../interface/Trends";
interface Props {
  setTrendsSnapshotModal: React.Dispatch<React.SetStateAction<boolean>>;
  snapShotURL: string;
  selectedProductType: string;
  selectedMaterial: string[];
  selectedStylingDetail: string[];
  startDate: string;
  endDate: string;
  region: string;
  genre: string;
  product: string;
  gender: string;
  brands: string[];
  source: string;
  category: string;
  color: string;
  req_id: string;
  trends: TrendInterface[];
  ageGroup: string;
  setLastReqId: React.Dispatch<React.SetStateAction<string | null>>;
  archiveTrendsArr: string[];
  setTookSnapshot: React.Dispatch<React.SetStateAction<boolean>>;
  setLastArchiveArr: React.Dispatch<React.SetStateAction<string[]>>;
}

const TrendsSnapshotModal: React.FC<Props> = ({
  setTrendsSnapshotModal,
  snapShotURL,
  selectedProductType,
  selectedMaterial,
  selectedStylingDetail,
  startDate,
  endDate,
  region,
  genre,
  product,
  gender,
  brands,
  source,
  category,
  color,
  req_id,
  trends,
  ageGroup,
  setLastReqId,
  archiveTrendsArr,
  setTookSnapshot,
  setLastArchiveArr,
}) => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userName = user?.name;
  const [snapshotName, setSnapshotName] = useState("");
  const userEmail = user?.email;
  const [userId, setUserId] = useState("");
  const [coverImages, setCoverImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setTrendsSnapshotModal(false);
  };
  function getRandomImages(posts: FeedItem[], count: number = 4): string[] {
    if (!posts || posts.length === 0) return [];

    const shuffledPosts = [...posts].sort(() => Math.random() - 0.5);

    const selectedPosts = shuffledPosts.slice(0, count);

    return selectedPosts.map(post => post.image_link);
  }
  const randomImages = getRandomImages(trends[0].posts, 4);
  const getUserByEmail = async () => {
    if (userEmail) {
      try {
        const res = await getUserId(userEmail);
        const fetchedUserId = res.data.data[0]?._id;
        if (fetchedUserId) {
          setUserId(fetchedUserId);
        } else {
          console.error("User ID not found.");
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    }
  };

  useEffect(() => {
    const randomImages = getRandomImages(trends[0].posts, 4);
    setCoverImages(randomImages);
    getUserByEmail();
  }, [userEmail]);

  const handleSave = async () => {
    if (snapshotName.trim() === "") {
      toast.error("Please enter a name for the snapshot", {
        hideProgressBar: true,
      });
      return;
    }
    const payload = {
      name: snapshotName,
      ownerId: userId,
      ownerName: userName,
      genericFilters: {
        preferenceFilters: {
          gender: gender ? gender : "",
          source: source ? source : "",
          startDate: startDate ? startDate : "",
          endDate: endDate ? endDate : "",
          region: region ? region : "",
          genre: genre ? genre : "",
          product: product ? product : "",
          brands: brands ? brands : [],
          category: category ? category : "",
          color: color ? color : "",
          ageGroup: ageGroup ? ageGroup : "",
        },
        trendAnalysisFilters: {
          product_type: selectedProductType ? selectedProductType : "All",
          material: selectedMaterial.length ? selectedMaterial.join(",") : "",
          styling_details: selectedStylingDetail.length
            ? selectedStylingDetail.join(",")
            : "",
        },
      },
      images: coverImages,
      req_id: req_id,
    };
    setLoading(true);
    try {
      await createSnapshot(payload, user);
      sessionStorage.removeItem("last_req_id");
      sessionStorage.removeItem("last_Archive_Arr");
      sessionStorage.setItem("last_req_id", req_id);
      sessionStorage.setItem(
        "last_Archive_Arr",
        JSON.stringify(archiveTrendsArr)
      );
      setTookSnapshot(true);
      setLastReqId(req_id);
      setLastArchiveArr(archiveTrendsArr);
      toast.success("Snapshot created successfully", {
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error creating snapshot:", error);
      toast.error("Error while creating snapshot", {
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
    setTrendsSnapshotModal(false);
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center ">
      {/* Modal Background */}

      <div
        className="fixed inset-0 bg-primary opacity-50"
        onClick={onClose}
      ></div>

      <div
        className="relative bg-[#FFFFFF] rounded-lg shadow-lg z-40 font-poppins"
        style={{ width: "459px", height: "474px" }}
      >
        <div className="flex justify-between max-h-[54px]">
          <div className="px-4 pt-2 text-[24px] font-poppins font-bold">
            Save Snapshot
          </div>

          <div className="px-4 py-4 cursor-pointer" onClick={onClose}>
            <CrossIcon width="16px" height="16px" />
          </div>
        </div>

        <img
          src={snapShotURL}
          alt="screenshot"
          className="object-cover  px-4 h-[284px] w-full "
        />

        <div className="mt-2 px-4">
          <label className="text-[14px] font-poppins font-normal text-primary">
            Snapshot Name
          </label>

          <input
            className="w-full px-2 py-1 border rounded-md border-disabled mt-1 focus:border-disabled focus:outline-none"
            onChange={e => setSnapshotName(e.target.value)}
          />
        </div>

        <div className="flex justify-center">
          <button
            className="w-[143px] rounded-full mt-4 bg-primary text-white py-2 disabled:bg-secondary"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>,

    document.body
  );
};

export default TrendsSnapshotModal;
