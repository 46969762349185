// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SourcesIcon({ fill }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4292 15.335C20.7391 17.2844 19.4618 18.9721 17.7731 20.1657C16.0843 21.3592 14.0672 22.0001 11.9992 22C7.64619 22 3.94219 19.218 2.56919 15.335C5.40378 17.0824 8.66928 18.0053 11.9992 18C15.4572 18 18.6872 17.025 21.4292 15.335ZM21.1252 7.905C21.773 9.34694 22.0685 10.9223 21.9872 12.501C19.1538 14.7713 15.6299 16.0058 11.9992 16C8.22119 16 4.74919 14.69 2.01119 12.5C1.93004 10.9216 2.22551 9.34663 2.87319 7.905C5.48966 9.91669 8.69878 11.005 11.9992 11C15.4332 11 18.5972 9.846 21.1252 7.905ZM11.9992 2C13.5847 1.99898 15.1477 2.37536 16.559 3.09803C17.9703 3.82071 19.1893 4.86892 20.1152 6.156C17.813 8.00119 14.9496 9.00459 11.9992 9C8.92919 9 6.10719 7.936 3.88319 6.156C4.80895 4.86874 6.0279 3.8204 7.4392 3.0977C8.85051 2.37501 10.4136 1.99873 11.9992 2Z"
        fill={fill}
      />
    </svg>
  );
}

export default SourcesIcon;
