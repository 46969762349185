import React, { useState } from "react";
import { ReactComponent as ExpandImage } from "../../../assets/icons/expand.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/heart.svg";
import { ReactComponent as Archive } from "../../../assets/icons/archive.svg";
import { ReactComponent as Unarchive } from "../../../assets/icons/unarchive.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import { FeedItem } from "../../../interface/DesignerInterface";
import Carousel from "./Carousel";
import SelectionChips from "./SelectionChips";
import { Board } from "../../../interface/BoardsInterface";
import Modal from "../../Common/Modal";
import SaveToBoard from "../SaveToBoard";
import TrendContentSelection from "./TrendContentSelection";
import { ReactComponent as BlackHeartIcon } from "../../../assets/icons/heartWithBlack.svg";

import ExpandedPostModal from "../../Common/ExpandedTrendsViewModal";
import ArchiveModal from "../../Archieve/ArchiveModal";
type TrendsProps = {
  isSidebarOpen: boolean;
  sidebarOpen: boolean;
  name: string;
  selection: string[];
  trendsOpen: boolean;
  posts: FeedItem[];
  privateBoards: Board[];
  setPrivateBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  publicBoards: Board[];
  setPublicBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  userId: string;
  req_id: string;
  trendSnapshotTrendsView?: boolean;
  snapshotId?: number;
  trendNo?: number;
  archive?: boolean;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  analysisResultSuccess?: boolean;
};

const Trends: React.FC<TrendsProps> = ({
  isSidebarOpen,
  sidebarOpen,
  name,
  selection,
  trendsOpen,
  posts,
  privateBoards,
  setPrivateBoards,
  publicBoards,
  setPublicBoards,
  userId,
  req_id,
  trendSnapshotTrendsView,
  snapshotId,
  trendNo,
  archive,
  setReload,
  analysisResultSuccess,
}) => {
  const [trendSaveToBoardOpen, setTrendSaveToBoardOpen] = useState(false);
  const [multiSelectContentOpen, setMultiSelectContentOpen] = useState(false);
  const [savedIndex, setSavedIndex] = useState<number>(0);
  const [savedData, setSavedData] = useState<FeedItem[]>([]);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  const closeArchiveModal = () => {
    setArchiveModalOpen(false);
  };
  const [allPagesData, setAllPagesData] = useState<Record<string, FeedItem[]>>(
    {}
  );
  const allPagesPosts = ([] as FeedItem[]).concat(
    ...Object.values(allPagesData)
  );

  const selectedAllPagesPosts = allPagesPosts.filter(item => item.isChecked);

  const handleOpenSaveToBoard = () => {
    setMultiSelectContentOpen(true);
  };
  const closeTrendSaveToBoard = () => setTrendSaveToBoardOpen(false);

  const handleMultiselectClose = () => setMultiSelectContentOpen(false);

  const [isExpandViewOpen, setIsExpandViewOpen] = useState(false);
  const handleExpandClick = () => {
    setIsExpandViewOpen(true);
  };
  const closeModal = () => {
    setIsExpandViewOpen(false);
  };

  return (
    <div className="space-y-4 mb-4">
      <div className="w-full flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          <span>{name}</span>
          <div className="w-[2px] h-[18px] bg-[#4b4b4b]"></div>
          {selection.map((value, index) => {
            return <SelectionChips key={index} value={value} />;
          })}
        </div>
        <div className="flex space-x-4">
          <div className="cursor-pointer" onClick={handleExpandClick}>
            <ExpandImage className="w-3 h-4" />
          </div>
          <div className="cursor-pointer">
            <HeartIcon className="w-4 h-4" onClick={handleOpenSaveToBoard} />
          </div>
          <div
            className={`${trendSnapshotTrendsView}?"":"cursor-pointer"`}
            onClick={() => {
              if (trendSnapshotTrendsView) return;
              setArchiveModalOpen(true);
            }}
          >
            {archive || trendSnapshotTrendsView ? (
              <Unarchive className="w-4 h-4" />
            ) : (
              <Archive className="w-4 h-4" />
            )}
          </div>
          {/* <div className="cursor-pointer">
            <Delete className="w-4 h-4" />
          </div> */}
        </div>
      </div>
      <div className="flex flex-wrap justify-evenly gap-4">
        <Carousel
          isSidebarOpen={isSidebarOpen}
          sidebarOpen={sidebarOpen}
          data={posts}
          trendsOpen={trendsOpen}
          privateBoards={privateBoards}
          setPrivateBoards={setPrivateBoards}
          publicBoards={publicBoards}
          setPublicBoards={setPublicBoards}
          userId={userId}
          trendSnapshotView={trendSnapshotTrendsView}
          archive={archive}
          analysisResultSuccess={analysisResultSuccess}
        />
      </div>
      {multiSelectContentOpen && (
        <TrendContentSelection
          onClose={handleMultiselectClose}
          setTrendSaveToBoardOpen={setTrendSaveToBoardOpen}
          setMultiSelectContentOpen={setMultiSelectContentOpen}
          name={name}
          selection={selection}
          userId={userId}
          req_id={req_id}
          allPagesData={allPagesData}
          setAllPagesData={setAllPagesData}
          trendSnapshotTrendsView={trendSnapshotTrendsView}
          snapshotId={snapshotId}
          trendNo={trendNo}
        />
      )}
      {trendSaveToBoardOpen && (
        <Modal
          isOpen={trendSaveToBoardOpen}
          onClose={closeTrendSaveToBoard}
          title="Save to Board"
          changeWidthandHeight={true}
        >
          <SaveToBoard
            publicBoards={publicBoards}
            setPublicBoards={setPublicBoards}
            privateBoards={privateBoards}
            setPrivateBoards={setPrivateBoards}
            onClose={closeTrendSaveToBoard}
            userId={userId}
            fromTrendBoard={true}
            data={selectedAllPagesPosts}
            setMultiSelectContentOpen={setMultiSelectContentOpen}
            trendSnapshotTrendsView={trendSnapshotTrendsView}
          />
        </Modal>
      )}
      {isExpandViewOpen && (
        <ExpandedPostModal
          name={name}
          onClose={closeModal}
          selections={selection}
          privateBoards={privateBoards}
          setPrivateBoards={setPrivateBoards}
          publicBoards={publicBoards}
          setPublicBoards={setPublicBoards}
          userId={userId}
          req_id={req_id}
          allPagesData={allPagesData}
          setAllPagesData={setAllPagesData}
          handleOpenSaveToBoard={handleOpenSaveToBoard}
          setIsExpandViewOpen={setIsExpandViewOpen}
          trendSnapshotTrendsView={trendSnapshotTrendsView}
          snapshotId={snapshotId}
          trendNo={trendNo}
          setArchiveModalOpen={setArchiveModalOpen}
          archive={archive}
        />
      )}
      {archiveModalOpen && (
        <ArchiveModal
          text={
            archive
              ? "Are you sure you want to Unarchive the trend?"
              : "Are you sure you want to Archive the trend?"
          }
          onClose={closeArchiveModal}
          req_id={req_id}
          name={name}
          setReload={setReload}
          archive={archive}
          setIsExpandViewOpen={setIsExpandViewOpen}
        />
      )}
    </div>
  );
};

export default Trends;
