import React, { useState, ChangeEvent, SyntheticEvent, useEffect } from "react";
import {
  addContentToDaily,
  addContentToInnovation,
} from "../../app/api/admin/content";
import { NewsItem } from "../../interface/NewsInterface";
import { toast } from "react-toastify";
import Modal from "../Common/Modal";
import { getBoardsByUserId } from "../../app/api/admin/board";
import { Board } from "../../interface/BoardsInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import CreateBoard from "../Boards/CreateBoard";
import moment from "moment";
import NewsImage from "./NewsImage";

type Props = {
  publicBoards?: Board[];
  setPublicBoards?: React.Dispatch<React.SetStateAction<Board[]>>;
  privateBoards?: Board[];
  setPrivateBoards?: React.Dispatch<React.SetStateAction<Board[]>>;
  selectedNews: NewsItem;
  onClose?: () => void | undefined;
  userId?: string;
  fromInnovation?: boolean;
  innovationId?: string;
};

const SaveToBoard: React.FC<Props> = ({
  selectedNews,
  publicBoards,
  setPublicBoards,
  privateBoards,
  setPrivateBoards,
  userId,
  onClose,
  fromInnovation,
  innovationId,
}) => {
  const [selectedBoards, setSelectedBoards] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const [notes, setNotes] = useState<string>();
  const handleCheckboxChange = (boardId: string) => {
    if (selectedBoards.includes(boardId)) {
      setSelectedBoards(selectedBoards.filter(id => id !== boardId));
    } else {
      setSelectedBoards([...selectedBoards, boardId]);
    }
  };

  const handleImageLink = async (
    source: string,
    url: string
  ): Promise<string> => {
    //Check if favicon available or not
    const fetchFavicon = (): Promise<boolean> => {
      return new Promise(resolve => {
        const img = new Image();
        img.src = `${new URL(url).origin}/favicon.ico`;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    };
    const isFaviconAvailable = await fetchFavicon();
    if (isFaviconAvailable) {
      return `${new URL(url).origin}/favicon.ico`;
    }

    //If favicon not available
    const firstWord = source.split(" ")[0];
    const canvas = document.createElement("canvas");
    canvas.width = 70;
    canvas.height = 70;
    const context = canvas.getContext("2d");
    if (context) {
      context.fillStyle = "#000000";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "#FFFFFF";
      context.font = "bold 20px Arial";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(firstWord, canvas.width / 2, canvas.height / 2);
      const imageUrl = canvas.toDataURL();
      return imageUrl;
    }
    return "https://www.bing.com/th?id=OVFT.94fhU2WuyPqa4wDZIRambS&pid=News";
  };

  const handleSubmit = async () => {
    if (!selectedNews) {
      console.error("No post selected");
      return;
    }

    try {
      if (fromInnovation && innovationId) {
        const payload = {
          board_ids: selectedBoards,
          category_name: "Innovation",
          date: moment(selectedNews?.datePublished).format("YYYY-MM-DD"),
          description: selectedNews?.description,
          media_urls: await handleImageLink(
            selectedNews?.source,
            selectedNews?.url
          ),
          title: selectedNews.title,
          innovationFeedId: innovationId,
          url: selectedNews?.url,
          source:
            selectedNews.source === "Bing News"
              ? selectedNews.provider
                ? selectedNews.provider[0]
                : "Bing News"
              : selectedNews.source,
          notes: notes,
        };

        await addContentToInnovation(payload);
        toast.success("Added content successfully", {
          hideProgressBar: true,
        });
        setSelectedBoards([]);
        onClose?.();
      } else {
        const payload = {
          board_ids: selectedBoards,
          category_name: "Daily",
          date: moment(selectedNews?.datePublished).format("YYYY-MM-DD"),
          description: selectedNews?.description,
          media_urls: await handleImageLink(
            selectedNews?.source,
            selectedNews?.url
          ),
          title: selectedNews.title,
          dailyNewsId: selectedNews._id,
          url: selectedNews?.url,
          source:
            selectedNews.source === "Bing News"
              ? selectedNews.provider && selectedNews.provider.length > 0
                ? selectedNews.provider[0]
                : "Bing News"
              : selectedNews.source,
          notes: notes,
        };
        await addContentToDaily(payload);
        toast.success("Added content successfully", {
          hideProgressBar: true,
        });
        setSelectedBoards([]);
        onClose?.();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.warn(
        error?.response.data.message && selectedBoards.length === 0
          ? "Select a board"
          : error?.response.data.message,
        {
          hideProgressBar: true,
        }
      );
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getBoards = async () => {
    const res = await getBoardsByUserId(userId || "");
    setPublicBoards?.(res?.data?.data?.public?.boards);
    setPrivateBoards?.(res?.data?.data?.private?.boards);
  };

  const filteredPublicBoards: Board[] | undefined = publicBoards?.filter(i =>
    i.board_name.toLowerCase().includes(searchString)
  );

  const filteredPrivateBoards: Board[] | undefined = privateBoards?.filter(i =>
    i.board_name.toLowerCase().includes(searchString)
  );

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    setSearchString(searchStr);
  };

  return (
    <div className="flex justify-center items-center max-h-[562px] w-[822px] bg-gray-100">
      <div>
        <div className="flex">
          <div className="w-1/2 pr-4 ">
            <div className="relative flex space-x-2">
              <NewsImage
                source={selectedNews.source}
                url={selectedNews.url}
                classProps="rounded-lg mb-4 w-[100px] h-[100px] object-fill"
              />
              <div className="font-bold text-sm">{selectedNews?.title}</div>
            </div>
            <div className="text-gray-400 text-sm max-h-[100px] overflow-y-auto">
              {selectedNews?.description}
            </div>
            <label className="block text-gray-700 mb-2 mt-2 text-sm">
              Enter Notes (optional)
            </label>
            <textarea
              className="w-full border rounded-lg p-2"
              placeholder="Input Text"
              onChange={e => {
                setNotes(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="w-1/2 p-2 border-t-0 border-r-0 border-2 border-gray-200">
            <div className="flex items-center mb-4">
              <input
                type="text"
                className="w-full border rounded-lg p-2"
                placeholder="Search Board"
                onChange={handleSearch}
              />
              <button
                className="ml-2 text-sm text-primary"
                onClick={toggleModal}
              >
                +
              </button>
            </div>
            <div className="min-h-[250px]  max-h-[350px] overflow-x-auto overflow-y-scroll scroller text-sm">
              {filteredPublicBoards?.length === 0 &&
              filteredPrivateBoards?.length === 0 ? (
                <div> No boards</div>
              ) : (
                <>
                  {filteredPublicBoards && (
                    <>
                      <h3 className="text-gray-700 mb-2">Public</h3>
                      <div className="mb-2">
                        {filteredPublicBoards?.map(board => (
                          <div className="mb-2 flex flex-row" key={board.id}>
                            <div className="mt-[2px]">
                              <input
                                type="checkbox"
                                id={board.id}
                                className="mr-2"
                                checked={selectedBoards.includes(board.id)}
                                onChange={() => handleCheckboxChange(board.id)}
                                style={{
                                  cursor: "pointer",
                                  width: "14px",
                                  height: "14px",
                                }}
                              />
                            </div>
                            <label htmlFor={board.id} className="text-gray-700">
                              {board.board_name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {filteredPrivateBoards && (
                    <>
                      <h3 className="text-gray-700 mb-2 mt-4">Private</h3>
                      {filteredPrivateBoards?.map(board => (
                        <div className="mb-2 flex flex-row" key={board.id}>
                          <div className="mt-[2px]">
                            <input
                              type="checkbox"
                              id={board.id}
                              className="mr-2"
                              checked={selectedBoards.includes(board.id)}
                              onChange={() => handleCheckboxChange(board.id)}
                              style={{
                                cursor: "pointer",
                                width: "14px",
                                height: "14px",
                              }}
                            />
                          </div>
                          <label htmlFor={board.id} className="text-gray-700">
                            {board.board_name}
                          </label>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 flex items-center justify-center  ">
          <button
            className="w-[80%] bg-black text-white py-3 rounded-full"
            onClick={handleSubmit}
          >
            Add to board{" "}
            {selectedBoards.length > 0 && `(${selectedBoards.length})`}
          </button>
        </div>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} title="Create Board">
          <CreateBoard
            onClose={closeModal}
            userId={userId as string}
            refresh={getBoards}
          />
        </Modal>
      )}
    </div>
  );
};

export default SaveToBoard;
