import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyContentGenerator.svg";
import { ReactComponent as UploadImage } from "../../assets/icons/uploadImageR.svg";
import { ReactComponent as Download } from "../../assets/icons/sentimentDownload.svg";
import { toast } from "react-toastify";
import axios from "axios";
import ImageCarousel from "../../components/SentimentAnalyzer/imageSection";
import UrlSection from "../../components/SentimentAnalyzer/urlSection";
import ImageSection from "../../components/SentimentAnalyzer/imageSection";
import { jsPDF } from "jspdf";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";
import DOMPurify from "dompurify";
import { ColorRing, ThreeDots } from "react-loader-spinner";
import imageCompression from "browser-image-compression";
import UploadImageIcon from "../../assets/icons/uploadImageIcon";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";
import { ReactComponent as Delete } from "../../assets/icons/deleteSentiment.svg";
import {
  compressImage,
  convertMarkdownToText,
  getFileName,
  getKeyForS3GenerativeFeature,
} from "../../utils";
import { uploadImage } from "../../app/api/uploadTos3";

type Props = {
  isSidebarOpen: boolean;
};

interface UrlState {
  title: string;
  url: string;
  content: string;
}
interface ImageState {
  url: string;
}

const SentimentAnalyser: React.FC<Props> = ({ isSidebarOpen }) => {
  const [selectedTone, setSelectedTone] = useState("Analytical & Objective");
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [imagesUrlS3, setImagesUrlS3] = useState<string[]>(["", "", "", ""]);
  const [imagesArr, setImagesArr] = useState<(File | null)[]>([
    null,
    null,
    null,
    null,
  ]);

  const [imageLoading, setImageLoading] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [imagesState, setImagesState] = useState<string[]>([]);
  const [urlsState, setUrlsState] = useState<UrlState[]>([]);
  const [generated, setGenerated] = useState<boolean>(false);
  const [sentimentLoading, setSentimentLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);
  const loadingRef = useRef<boolean>(false);
  const sentimentLoadingRef = useRef<boolean>(false);

  useEffect(() => {
    const storedOutput = sessionStorage.getItem("sentimentOutput");
    const storedImages = sessionStorage.getItem("sentimentImages");
    const storedUrls = sessionStorage.getItem("sentimentUrls");
    const storedInput = sessionStorage.getItem("sentimentInput");
    const generatedOutputDone = sessionStorage.getItem("generatedOutputDone");

    if (storedOutput) {
      setOutput(storedOutput);
    }
    if (storedImages) {
      setImagesState(JSON.parse(storedImages));
    }
    if (storedUrls) {
      setUrlsState(JSON.parse(storedUrls));
    }
    if (generatedOutputDone) {
      setGenerated(true);
    }
    if (storedInput) {
      setInput(storedInput);
    }
    inputRef.current?.focus();
  }, []);

  const handleSelect = (option: React.SetStateAction<string>) => {
    setSelectedTone(option);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleCopyToClipboard = () => {
    if (output) {
      const cleanOutput = DOMPurify.sanitize(output);
      const plainTextOutput = convertMarkdownToText(cleanOutput);

      navigator.clipboard
        .writeText(plainTextOutput)
        .then(() => {
          toast.success("Text copied", {
            hideProgressBar: true,
          });
        })
        .catch(error => {
          toast.error("Error copying text", {
            hideProgressBar: true,
          });
        });
    }
  };
  const handleDownload = async () => {
    const doc = new jsPDF();
    const cleanOutput = DOMPurify.sanitize(output);
    const plainTextOutput = convertMarkdownToText(cleanOutput);

    doc.setFontSize(16);
    doc.text("Content:", 10, 20);

    doc.setFontSize(12);
    const splitText = doc.splitTextToSize(plainTextOutput, 180);
    let yPosition = 30;

    splitText.forEach((line: string) => {
      if (yPosition > 280) {
        doc.addPage();
        yPosition = 20;
      }
      doc.text(line, 10, yPosition);
      yPosition += 7;
    });

    doc.addPage();
    doc.setFontSize(16);
    doc.text("Sources:", 10, 20);

    doc.setFontSize(12);
    yPosition = 30;

    urlsState.forEach((url, index) => {
      if (yPosition > 280) {
        doc.addPage();
        yPosition = 20;
      }
      const linkText = `${index + 1}. ${url.title}`;
      const splitUrlText = doc.splitTextToSize(linkText, 180);
      splitUrlText.forEach((line: string) => {
        if (yPosition > 280) {
          doc.addPage();
          yPosition = 20;
        }
        doc.setFontSize(8);
        doc.text(line, 10, yPosition);
        yPosition += 7;
      });
      doc.textWithLink(url.url, 10, yPosition, { url: url.url });
      yPosition += 7;
    });

    const fileName =
      input.length > 0
        ? input.length > 40
          ? input.slice(0, 40) + ".."
          : input
        : "ImageAnalysis";
    const firstChar = fileName[0].toUpperCase();
    doc.save(firstChar + fileName.slice(1) + ".pdf");
  };

  const handleGenerate = async () => {
    let eventSource: EventSource | null = null;
    try {
      setOutput("");
      setUrlsState([]);
      setImagesState([]);
      sessionStorage.removeItem("sentimentOutput");
      sessionStorage.removeItem("sentimentImages");
      sessionStorage.removeItem("sentimentUrls");

      setGenerated(false);
      sessionStorage.removeItem("generatedOutputDone");
      sessionStorage.setItem("sentimentInput", input);

      if (!input.trim() && imagesArr.length === 0) {
        toast.warn("Please enter your text in the below text area.", {
          hideProgressBar: true,
        });
        return;
      }

      setLoading(true);
      setSentimentLoading(true);

      // Set up eventSource first to handle real-time updates
      eventSource = new EventSource(`${BASE_URL}sentiment-gen`, {
        withCredentials: true,
      });

      eventSource.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data) {
          eventSource?.close();
          setSentimentLoading(false);
        }
        if (data.urls) {
          setUrlsState(data.urls);
          sessionStorage.setItem("sentimentUrls", JSON.stringify(data.urls));
        }
        if (data.images) {
          setImagesState(data.images);
          sessionStorage.setItem(
            "sentimentImages",
            JSON.stringify(data.images)
          );
        }
      };

      eventSource.onerror = function (error) {
        console.error("EventSource failed:", error);
        eventSource?.close();
        toast.error("Error connecting to server", { hideProgressBar: true });
        setLoading(false);
        setSentimentLoading(false);
      };

      // Fetch the actual sentiment analysis data
      const bodyData = JSON.stringify({
        text: input || "",
        images: imagesUrlS3, // assuming imagesUrlS3 is a string or array of S3 URLs
      });

      const response = await fetch(`${BASE_URL}sentiment-gen`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
      });

      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let newOutput = "";

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          if (doneReading) {
            setGenerated(true);
            sessionStorage.setItem("generatedOutputDone", "true");
            sessionStorage.setItem("sentimentOutput", newOutput);
            setLoading(false);
          }
          const chunk = decoder.decode(value, { stream: true });

          newOutput += chunk;
          setOutput(prev => {
            const updatedOutput = prev + chunk;
            const outputElement = document.querySelector(".overflow-y-auto");
            if (outputElement) {
              const isScrolledToBottom =
                outputElement.scrollHeight - outputElement.clientHeight <=
                outputElement.scrollTop + 1;
              if (isScrolledToBottom) {
                setTimeout(() => {
                  outputElement.scrollTop = outputElement.scrollHeight;
                }, 0);
              }
            }
            return updatedOutput;
          });
          if (!loadingRef.current) {
            done = true;
          }
        }
      }
    } catch (error) {
      console.error("Error connecting to server:", error);
      if (eventSource) eventSource.close();
      setLoading(false);
      setSentimentLoading(false);

      toast.error("Error connecting to server", {
        hideProgressBar: true,
      });
    }
  };
  const getImageUrl = async (
    file: File,
    availableIndex: number,
    newImagesLoading: boolean[]
  ): Promise<string> => {
    // Set loading state for the available index
    newImagesLoading[availableIndex] = true;
    setImageLoading([...newImagesLoading]);

    const fileName = getFileName(file);
    const key = getKeyForS3GenerativeFeature(fileName);
    const res = await uploadImage(key, file);

    // Reset loading state after upload
    newImagesLoading[availableIndex] = false;
    // setImageLoading([...newImagesLoading]);

    return `${process.env.REACT_APP_CLOUD_FRONT_URL}${res.key}`;
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImagesArr = [...imagesArr];
      const newImagesFrontend = [...imagesUrlS3];
      const newImagesLoading = [...imageLoading];

      // this is to check 4 images
      const filteredArr = newImagesArr.filter(image => image !== null);
      if (filteredArr.length + files.length > 4) {
        setImageLoading([false, false, false, false]);
        toast.error("You can only upload a maximum of 4 images.", {
          hideProgressBar: true,
        });
        return;
      }

      const availableIndices = newImagesArr
        .map((image, index) => (image === null ? index : -1))
        .filter(index => index !== -1);
      // this is to check duplicate image
      for (let i = 0; i < files.length; i++) {
        newImagesLoading[availableIndices[i]] = true;
      }
      setImageLoading([...newImagesLoading]);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const isDuplicate = newImagesArr.some(
          existingFile => existingFile?.name === file.name
        );

        if (isDuplicate) {
          toast.error("Duplicate image selected.", {
            hideProgressBar: true,
          });
          setImageLoading([false, false, false, false]);
          return;
        }

        // Get the current available index for this file
        const availableIndex = availableIndices[i];
        if (availableIndex !== undefined) {
          // Set loading true for each available index
          const imagesS3 = await getImageUrl(
            file,
            availableIndex,
            newImagesLoading
          );
          newImagesArr[availableIndex] = file;
          newImagesFrontend[availableIndex] = imagesS3;
        } else {
          toast.error("You can only upload a maximum of 4 images.", {
            hideProgressBar: true,
          });
          setImageLoading([false, false, false, false]);
          return;
        }
        setImageLoading([...newImagesLoading]);
        setImagesArr(newImagesArr);
        setImagesUrlS3(newImagesFrontend);
      }
    }
  };

  const handleImageRemove = (index: number) => {
    // this is s3 image removal
    setImagesUrlS3(prev => {
      const newImages = [...prev];
      newImages[index] = "";
      return newImages;
    });

    //this is local image removal for files removal
    setImagesArr(prev => {
      const newImagesArr = [...prev];
      newImagesArr[index] = null;

      return newImagesArr;
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const triggerFileInput = (index: number) => () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleClearAll = () => {
    setInput("");
    setImagesUrlS3(["", "", "", ""]);
    setImagesArr([null, null, null, null]);
    setUrlsState([]);
    setImagesState([]);
    setOutput("");
    setSelectedTone("Analytical & Objective");
    setLoading(false);
    setGenerated(false);
    sessionStorage.removeItem("generatedOutputDone");
    sessionStorage.removeItem("sentimentOutput");
    sessionStorage.removeItem("sentimentImages");
    sessionStorage.removeItem("sentimentUrls");
    sessionStorage.removeItem("sentimentInput");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSidebarToggle = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleStop = () => {
    setLoading(false);
    setSentimentLoading(false);
    toast.success("Generation Stopped", {
      hideProgressBar: true,
    });
  };

  useEffect(() => {
    loadingRef.current = loading;
    sentimentLoadingRef.current = sentimentLoading;
  }, [loading, sentimentLoading]);

  return (
    <>
      <div
        className={`h-[calc(100vh-111px)] bg-[#fff] ${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} transition-all duration-500 ease-in-out  ml-1 fixed`}
      >
        <div className="flex space-x-[1vw] ml-2">
          <div
            className={`ml-2 ${isSidebarCollapsed ? "w-[100%]" : "w-[87%]"}  h-[calc(100vh-215px)] border pl-2 pr-1 mt-4 border-default  rounded-lg flex flex-row  transition-all duration-500 ease-in-out"`}
          >
            <div className="h-full max-h-[100%] w-[87%] overflow-y-auto  border-r border-default">
              {loading && !output ? (
                <div className="flex justify-center items-center h-full">
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#212121",
                      "#4d4d4d",
                      "#D3C0B6",
                      "#9e9e9e",
                      "#f8f6f4",
                    ]}
                  />
                </div>
              ) : (
                output && (
                  <div>
                    <ReactMarkdown
                      className="markdown font-avenir text-secondary text-[14px] font-normal leading-[18.9px] tracking-[0.21px] p-2"
                      rehypePlugins={[rehypeRaw, rehypeHighlight]}
                      remarkPlugins={[[remarkGfm, { breaks: true }]]} // Enable breaks for new lines
                      components={{
                        ul: ({ children }) => (
                          <ul className="my-0 ml-2 list-[circle]">
                            {children}
                          </ul>
                        ),
                        ol: ({ children }) => (
                          <ol className="list-decimal my-0 ml-2">{children}</ol>
                        ),
                        li: ({ children }) => (
                          <li className="my-1 ml-2">{children}</li>
                        ),
                        a: ({ href, children }) => (
                          <a
                            href={href}
                            className="underline underline-offset-2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {children}
                          </a>
                        ),
                        hr: () => null,
                      }}
                    >
                      {output.replace(/\*{4}/g, "**")}
                    </ReactMarkdown>
                  </div>
                )
              )}
            </div>
            <div className="h-[98%] w-[17%] pt-2 overflow-y-auto scroller bg-white sticky right-0">
              <div className="">
                <div className=" font-poppins text-[14px] mx-auto text-primary sticky top-0 font-bold text-center">
                  <span>Sources</span>
                </div>

                {loading ? (
                  <div className="flex justify-center items-center h-full">
                    <ColorRing
                      visible={true}
                      height="60"
                      width="60"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#212121",
                        "#4d4d4d",
                        "#D3C0B6",
                        "#9e9e9e",
                        "#f8f6f4",
                      ]}
                    />
                  </div>
                ) : urlsState.length === 0 && generated ? (
                  <div className="flex justify-center items-center h-full">
                    <span className="text-gray-400">No sources Found</span>
                  </div>
                ) : (
                  <div>
                    <UrlSection links={urlsState} />
                  </div>
                )}
              </div>
              <div className=" sticky bottom-0 bg-white ">
                {generated && (
                  <div className="flex justify-center pt-2 gap-2">
                    <div
                      className="text-sm cursor-pointer  text-red-500  "
                      onClick={handleClearAll}
                    >
                      Clear All
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={handleCopyToClipboard}
                    >
                      <CopyToClipboard />
                    </div>
                    <div className="cursor-pointer" onClick={handleDownload}>
                      <Download />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={` h-[calc(100vh-210px)] ${isSidebarCollapsed ? "w-[27px]" : "w-[130px]"} transition-all duration-500 ease-in-out  flex  mt-1 pr-4`}
          >
            <div className={`flex flex-col `}>
              <div className="font-poppins text-[14px] text-primary font-bold flex flex-row  items-center gap-10">
                <div
                  className={`flex items-center text-primary rounded-2xl cursor-pointer ${
                    isSidebarCollapsed ? "justify-center" : " "
                  }`}
                  onClick={handleSidebarToggle}
                >
                  {isSidebarCollapsed ? (
                    <div className="flex flex-col items-center">
                      <LeftArrow className="text-lg mb-2 mt-3" />
                      <div>
                        {"SEGAMI".split("").map((letter, index) => (
                          <span
                            key={index}
                            className="block -rotate-90 font-semibold text-secondary"
                            style={{ marginBottom: "-8px" }}
                          >
                            {letter}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center mt-1">
                      <RightArrow className="text-lg mr-2" />
                      <span className="sticky top-0 transition-all mt-[10px] duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-3 text-secondary text-[18px]">
                        Images
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {!isSidebarCollapsed && (
                <>
                  {loading ? (
                    <div className="flex justify-center items-center h-full">
                      <ColorRing
                        visible={true}
                        height="60"
                        width="60"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={[
                          "#212121",
                          "#4d4d4d",
                          "#D3C0B6",
                          "#9e9e9e",
                          "#f8f6f4",
                        ]}
                      />
                    </div>
                  ) : (
                    <ImageSection images={imagesState} generated={generated} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between ml-4">
          <div
            className={`flex mt-2 ${
              isSidebarCollapsed ? "w-[1015px]" : "w-[920px]"
            } md:h-[100px] border-2 border-gray-300 rounded-lg p-2 mr-4 overflow-y-auto relative transition-all duration-300 ease-in-out`}
          >
            <div className="flex items-start w-full h-full ">
              <textarea
                className="outline-none flex-1 text-secondary font-avenir font-normal resize-none h-full pl-2"
                placeholder="Enter the text"
                value={input}
                onChange={handleInputChange}
                ref={inputRef}
                onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                  if (e.key === "Enter" && !e.shiftKey && !loading) {
                    e.preventDefault();
                    handleGenerate();
                  } else if (e.key === "Enter" && e.shiftKey) {
                    e.preventDefault();
                    const currentValue = inputRef.current?.value || "";
                    const cursorPosition =
                      inputRef.current?.selectionStart || 0;

                    const updatedValue =
                      currentValue.slice(0, cursorPosition) +
                      "\n" +
                      currentValue.slice(cursorPosition);
                    inputRef.current!.value = updatedValue;

                    inputRef.current!.selectionStart = cursorPosition + 1;
                    inputRef.current!.selectionEnd = cursorPosition + 1;
                    setInput(updatedValue);
                  }
                }}
              />

              <div className="grid grid-cols-2 gap-1 relative">
                {imagesUrlS3?.map((image, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer relative `}
                    onClick={triggerFileInput(index)}
                  >
                    {imageLoading[index] ? (
                      <div className="flex justify-center items-center w-[72px] h-[37px]">
                        <ThreeDots
                          visible={true}
                          height="20"
                          width="20"
                          color="#212121"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : image ? (
                      <div className="relative">
                        <div
                          className="bg-white absolute top-0 right-0 w-4 h-4 flex items-center justify-center border rounded cursor-pointer"
                          onClick={e => {
                            e.stopPropagation();
                            handleImageRemove(index);
                          }}
                        >
                          <Delete />
                        </div>
                        <img
                          src={image}
                          alt={`Uploaded ${index + 1}`}
                          className="w-[72px] h-[37px] object-cover rounded"
                        />
                      </div>
                    ) : (
                      <UploadImageIcon />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={e => handleImageUpload(e)}
              ref={fileInputRef}
              multiple
              style={{ display: "none" }}
            />
          </div>
          <div
            className={`flex items-center ${isSidebarCollapsed ? "mr-20" : "mr-48"} transition-all duration-300 ease-in-out`}
          >
            <div className="flex flex-col gap-1.5">
              <div className="text-[14px] font-poppins font-bold">
                Search Type
              </div>

              <label className="flex items-center text-[12px] font-poppins text-primary">
                <input
                  type="radio"
                  name="searchType"
                  value="Internet"
                  checked={true}
                  onChange={() => handleSelect("Internet")}
                  className="mr-2 accent-black "
                />
                Internet
              </label>

              <button className="group font-avenir bg-primary text-[12px] text-inverted w-[90px] h-[26px] rounded-[32px] cursor-pointer">
                {loading ? (
                  <div className="relative">
                    <p className="block cursor-pointer" onClick={handleStop}>
                      Stop
                    </p>
                  </div>
                ) : (
                  <div>
                    <p
                      className="block cursor-pointer"
                      onClick={handleGenerate}
                    >
                      Generate
                    </p>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SentimentAnalyser;
