import React, { useEffect, useState } from "react";
import { ReactComponent as BackIcon } from "../assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import Trends from "../components/DesignerPage/Trends/Trends";
import { GetTrendsParams } from "../app/api/trends";
import { TrendInterface } from "../interface/Trends";
import { getArchivedTrend } from "../app/api/archive";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Board } from "../interface/BoardsInterface";
import { getUserId } from "../app/api/user/user";
import { getBoardsByUserId } from "../app/api/admin/board";
import Skeleton from "react-loading-skeleton";
import Pagination from "../components/Target/Pagination";

type Props = {
  isSidebarOpen: boolean;
};

const Archive: React.FC<Props> = ({ isSidebarOpen }) => {
  const [reqId, setReqId] = useState<string>("");
  const [trends, setTrends] = useState<TrendInterface[]>([]);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [archiveTotalPages, setArchiveTotalPages] = useState<number>(1);
  const pageSize = 10;
  const posts_per_trend = 50;

  const userEmail = user?.email;
  const [userId, setUserId] = useState("");
  const [boardsList, setBoardsList] = useState<Board[]>([]);
  const [publicBoards, setPublicBoards] = useState<Board[]>([]);
  const [privateBoards, setPrivateBoards] = useState<Board[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleNavigate = (): void => {
    navigate("/designer-page");
  };

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem("trends");
    const trendFilters: GetTrendsParams = JSON.parse(
      sessionStorageData || "{}"
    );
    if (trendFilters?.req_id) {
      setReqId(trendFilters.req_id);
    }
  }, []);

  const getTrends = async () => {
    try {
      setLoading(true);
      const params = {
        req_id: reqId,
        posts_per_trend,
        page,
        pageSize,
      };
      const response = await getArchivedTrend(params, user);
      setTrends(response?.data?.trends);
      setArchiveTotalPages(Math.ceil(response?.data?.trendCount / pageSize));
    } catch (error) {
      if ((error as AxiosError)?.response?.status !== 400) {
        toast.error("Error Fetching Archived Trends", {
          hideProgressBar: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleArchivePageChange = (value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (reqId) {
      getTrends();
    }
  }, [reqId, page]);

  useEffect(() => {
    if (reqId && reload) {
      if (page !== 1 && trends.length === 1) {
        setPage(prev => prev - 1);
      } else {
        getTrends();
      }
      setReload(false);
    }
  }, [reload]);

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res?.data?.data[0]._id);
    }
  };

  const getBoardsByUser = async () => {
    if (userId) {
      const res = await getBoardsByUserId(userId);
      setPublicBoards(res?.data?.data.public.boards);
      setPrivateBoards(res?.data?.data.private.boards);
      setBoardsList(res?.data?.data);
    }
  };

  useEffect(() => {
    getUserByEmail();
    getBoardsByUser();
  }, [userId]);

  if (loading) {
    return (
      <div className="flex flex-wrap justify-around space-x-4 w-full h-full bg-white p-4">
        {Array.from({ length: 12 }).map((_, index) => (
          <div
            key={index}
            className="w-[240px] mt-4 p-0 pb-3 gap-2 border border-light rounded-2xl relative cursor-pointer"
          >
            <div className="relative w-full h-auto">
              <Skeleton height={200} width={240} className="rounded-t-2xl" />
              <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                <Skeleton width={50} />
              </div>
              <div className="absolute bottom-2 right-2 flex space-x-1">
                <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                  <Skeleton circle={true} height={20} width={20} />
                </div>
                <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                  <Skeleton circle={true} height={20} width={20} />
                </div>
                <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                  <Skeleton circle={true} height={20} width={20} />
                </div>
                <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                  <Skeleton circle={true} height={20} width={20} />
                </div>
              </div>
            </div>
            <div className="p-2 space-y-0.5">
              <Skeleton width={100} />
              <Skeleton width={150} />
              <Skeleton width={120} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={`bg-white ml-2 px-4 py-2 h-[calc(100vh-110px)] ${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} fixed`}
    >
      <div className="flex">
        <div
          className="w-8 h-8 flex  items-center cursor-pointer"
          onClick={handleNavigate}
        >
          <BackIcon />
        </div>
        <div className="flex justify-center items-center">
          <p className="font-bold text-[16px] font-poppins text-secondary">
            Archived Trends
          </p>
        </div>
      </div>
      <>
        {trends.length > 0 ? (
          <>
            <div
              className={` overflow-y-scroll scroller ${archiveTotalPages > 1 ? "h-[calc(100%-55px)]" : "h-full"} mt-4`}
            >
              {trends.map((item, index) => {
                return (
                  <Trends
                    key={index}
                    isSidebarOpen={isSidebarOpen}
                    sidebarOpen={false}
                    name={item.name}
                    selection={item.trendCategory}
                    trendsOpen={false}
                    posts={item.posts}
                    privateBoards={privateBoards}
                    setPrivateBoards={setPrivateBoards}
                    publicBoards={publicBoards}
                    setPublicBoards={setPublicBoards}
                    userId={userId}
                    req_id={reqId}
                    setReload={setReload}
                    archive={true}
                    analysisResultSuccess={true}
                  />
                );
              })}
            </div>
            <div
              className={`mt-1 ${archiveTotalPages > 1 ? "block" : "hidden"}`}
            >
              <Pagination
                currentPage={page}
                totalPages={archiveTotalPages}
                onPageChange={handleArchivePageChange}
              />
            </div>
          </>
        ) : (
          <div className="font-poppins text-center mt-10 w-full">
            No Archieved Trends Found
          </div>
        )}
      </>
    </div>
  );
};

export default Archive;
