// InstaPost.tsx
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ReturnArrow } from "../../assets/icons/returnArrow.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { ReactComponent as ExpandSvg } from "../../assets/icons/expand.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { FeedItem, SelectedPost } from "../../interface/DesignerInterface";
import { copyImageToClipboard, getImageLink } from "../../utils";
import Modal from "../Common/Modal";
import DownloadImage from "./DownloadImage";
import ExpandView from "../../components/DesignerPage/ExpandView";
import SaveToBoard from "./SaveToBoard";
import { Board } from "../../interface/BoardsInterface";

interface InstaPostProps {
  item: FeedItem;
  trendsOpen: boolean;
  privateBoards: Board[];
  setPrivateBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  publicBoards: Board[];
  setPublicBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  userId: string;
  analysisResultSuccess?: boolean;
}

const InstaPost: React.FC<InstaPostProps> = ({
  item,
  trendsOpen,
  privateBoards,
  setPrivateBoards,
  publicBoards,
  setPublicBoards,
  userId,
  analysisResultSuccess,
}) => {
  const [isDownloadImageOpen, setDownloadImageOpen] = useState(false);
  const [isExpandViewOpen, setExpandViewOpen] = useState(false);
  const [saveToBoardImage, setSaveToBoardImage] = useState<string>("");
  const selectedPost = {
    imageUrl: getImageLink(item.image_link),
    brandName: item.brand.brandName,
    description: item.description,
    source: item.handle,
    postId: item.postId,
    permalink: item.permalink,
    liveSince: item.postDate,
  };

  const openExpandView = () => {
    setExpandViewOpen(true);
  };

  const closeExpandView = () => {
    setExpandViewOpen(false);
  };
  const openDownloadImage = () => {
    setDownloadImageOpen(true);
  };

  const closeDownloadImage = () => setDownloadImageOpen(false);
  const handleCopyImageToClipboard = async (imageUrl: string) => {
    await copyImageToClipboard(imageUrl, `IG/${item.handle}`);
  };

  const [isSaveToBoardOpen, setSaveToBoardOpen] = useState(false);
  const closeSaveToBoard = () => setSaveToBoardOpen(false);

  const formatDate = (date: Date) => {
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const openSaveToBoard = (imageUrl: string) => {
    setSaveToBoardImage(imageUrl);
    setSaveToBoardOpen(true);
  };
  const handleOpenSaveToBoard = (imageUrl: string, post: FeedItem) => {
    setSaveToBoardImage(imageUrl);
    setSaveToBoardOpen(true);
  };

  if (!item.image_link || item.image_link === "N/A") {
    return null;
  }

  return (
    <div
      key={item.postId}
      className={`${trendsOpen ? "w-[158px]" : "w-[240px]"} ${trendsOpen ? "h-[260px]" : "h-[380px]"} p-0 pb-3 gap-2 border border-light rounded-2xl relative`}
    >
      <div
        className={`relative w-full ${trendsOpen ? "h-[190px]" : "h-[300px]"} hover:transition-all duration-100`}
      >
        <div
          className={`relative w-full ${trendsOpen ? "h-[190px]" : "h-[300px]"}`}
        >
          <img
            src={getImageLink(item.image_link)}
            alt={`Image ${item.postId}`}
            className="w-full h-full object-cover rounded-t-2xl"
          />
        </div>
        <div
          className={`absolute top-0 right-0 bg-translucent text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl ${trendsOpen ? "px-2 py-1" : "px-1 py-0.5"} ${trendsOpen ? "text-[10px]" : "text-xs"}`}
        >
          IG/{item.handle}
        </div>
        <div className="absolute bottom-2 right-2 flex space-x-1">
          <div
            className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
            onClick={() => window.open(`${item?.permalink}`, "_blank")}
          >
            <ReturnArrow />
          </div>
          <div
            className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
            onClick={() =>
              handleOpenSaveToBoard(getImageLink(item.image_link), item)
            }
          >
            <HeartIcon />
          </div>
          <div
            className="bg-white flex w-5 h-5 shadow-2xl shadow-gray-900 items-center justify-center rounded-full cursor-pointer"
            onClick={async () => await openDownloadImage()}
          >
            <Download />
          </div>
          <div
            className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
            onClick={() =>
              handleCopyImageToClipboard(getImageLink(item.image_link))
            }
          >
            <CopyToClipboard />
          </div>
        </div>
      </div>
      <div className={`p-2 space-y-0.5 ${trendsOpen ? "leading-3" : ""}`}>
        <div
          className={`${trendsOpen ? "text-[10px]" : "text-xs"} mt-1 text-primary font-medium`}
        >
          Brand: {item.brand.brandName}
        </div>
        <div
          className={`${trendsOpen ? "text-[10px]" : "text-xs"} text-primary`}
        >
          Date: {formatDate(new Date(item.postDate))}
        </div>
        <div
          className={`flex justify-between items-center ${trendsOpen ? "text-[10px]" : "text-xs"} text-tertiary`}
        >
          <span className={`w-[95%] truncate`}>{item.description}</span>
        </div>
        <button
          className={`absolute bottom-1.5 right-2`}
          onClick={openExpandView}
        >
          <ExpandSvg className="w-2.5 h-2.5" />
        </button>
      </div>
      <Modal
        isOpen={isDownloadImageOpen}
        onClose={closeDownloadImage}
        title="Download Image"
      >
        <DownloadImage
          imageUrl={getImageLink(item.image_link)}
          source={item.handle}
          brandName={item.brand.brandName}
        />
      </Modal>
      <Modal
        isOpen={isExpandViewOpen}
        onClose={closeExpandView}
        title="Expanded View"
      >
        {selectedPost && (
          <ExpandView
            imageUrl={selectedPost.imageUrl}
            brandName={selectedPost.brandName}
            description={selectedPost.description}
            source={selectedPost.source}
            openSaveToBoard={openSaveToBoard}
            openDownloadImage={openDownloadImage}
            postId={selectedPost.postId}
            permalink={selectedPost.permalink}
            liveSince={selectedPost.liveSince}
          />
        )}
      </Modal>
      <Modal
        isOpen={isSaveToBoardOpen}
        onClose={closeSaveToBoard}
        title="Save to Board"
      >
        {saveToBoardImage && (
          <SaveToBoard
            imageUrl={saveToBoardImage}
            selectedPost={selectedPost}
            publicBoards={publicBoards}
            setPublicBoards={setPublicBoards}
            privateBoards={privateBoards}
            setPrivateBoards={setPrivateBoards}
            onClose={closeSaveToBoard}
            userId={userId}
            analysisResultSuccess={analysisResultSuccess}
          />
        )}
      </Modal>
    </div>
  );
};

export default InstaPost;
