import React from "react";
import { getBoardByBoardId } from "../../app/api/admin/board";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  deleteContentByBoardId,
  deleteContentById,
  getContentByBoard,
} from "../../app/api/admin/content";
import { Content } from "../../interface/ContentInterface";

interface deleteModalProps {
  onClose: () => void;
  contentId: string;
  boardId?: string;
  setContentData?: React.Dispatch<React.SetStateAction<Content[]>>;
  closeExpandView?: () => void;
  userBoardAccessLevelIds?: string[];
  activeTabId?: string;
  fromExpandedView?: boolean;
}
const DeleteModal: React.FC<deleteModalProps> = ({
  onClose,
  boardId,
  contentId,
  setContentData,
  closeExpandView,
  userBoardAccessLevelIds,
  activeTabId,
  fromExpandedView,
}) => {
  const handleRemoveContent = async () => {
    try {
      if (contentId) {
        if (
          userBoardAccessLevelIds?.includes(activeTabId || "") ||
          fromExpandedView
        ) {
          await deleteContentByBoardId(contentId || "", boardId || "");
          await toast.success("Successfully deleted the content", {
            hideProgressBar: true,
          });
          onClose?.();
          closeExpandView?.();
          getBoardData?.(boardId);
        } else {
          toast.error("You don't have edit access", {
            hideProgressBar: true,
          });
        }
      }
    } catch (e) {
      toast.error("Error while deleting the content", {
        hideProgressBar: true,
      });
    }
  };

  const getBoardData = async (boardId: string = "") => {
    await getBoardByBoardId(boardId || "");
    const contentResponse = await getContentByBoard(boardId || "", 1);
    setContentData?.(contentResponse.data.data.contents);
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-8">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className=" relative bg-white rounded-lg shadow-sm z-60 font-poppins py-4 px-2"
        style={{ width: "376px", height: "116px", overflow: "hidden" }}
      >
        <div className="flex items-center justify-center  bg-gray-100 ">
          <div className="bg-white rounded-lg ">
            <h2 className="text-xl font-bold mb-4">
              Are you sure you want to remove?
            </h2>

            <div className="flex justify-center space-x-14">
              <button
                className="bg-white text-gray-600 border border-gray-300 rounded-full px-6 py-2"
                onClick={() => onClose()}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white rounded-full px-6 py-2"
                onClick={handleRemoveContent}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
