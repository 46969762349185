import React from "react";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

type CommonModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  changeWidthandHeight?: boolean;
};

const Modal: React.FC<CommonModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  changeWidthandHeight,
}) => {
  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white rounded-lg shadow-lg p-4 ${changeWidthandHeight ? "" : "max-w-lg w-full"}  max-h-[90vh]  z-10`}
      >
        <div className="flex justify-between items-center ">
          <h1 className="text-xl font-bold">{title}</h1>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <Close />
          </button>
        </div>
        <div className="overflow-y-auto max-h-[80vh] scroller">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
